import React from 'react';
import styled from 'styled-components';
import iconClockStopwatch from 'assets/images/_icons/iconClockStopwatch.svg';
import iconFileShield from 'assets/images/_icons/iconFileShield.svg';
import iconGlobe from 'assets/images/_icons/iconGlobe.svg';
import iconIntersectSquare from 'assets/images/_icons/iconIntersectSquare.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Our US Offshore Software Development Services?</Title>
      <Content>
        {data.map(({ icon, sub, title }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconGlobe,
    title: 'Proven Experience in Offshore Development',
    sub: 'With extensive experience supporting US-based companies, we understand the unique challenges and needs of offshore development, offering reliable, high-quality solutions.',
  },
  {
    icon: iconIntersectSquare,
    title: 'Customizable Engagement Models',
    sub: 'Choose from flexible engagement models designed to fit your project needs, whether for long-term partnerships or specific development phases.',
  },
  {
    icon: iconClockStopwatch,
    title: 'Significant Time and Process Savings',
    sub: 'Our streamlined processes, expert project managers, and skilled teams minimize development time, reduce administrative overhead, and accelerate project delivery.',
  },
  {
    icon: iconFileShield,
    title: 'Commitment to Security and Compliance',
    sub: 'Data protection is paramount. We adhere to stringent security protocols, ensuring full compliance with US standards for IP protection and data privacy.',
  },
];
