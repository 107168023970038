import { ClickableList } from 'components/_layout/Header/Tab.styled';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import { useTheme } from 'layout/ThemeContext';
import React from 'react';
import { B2, B4 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { ROUTE_DEEPTECH, ROUTE_HARDWARE, ROUTE_SOFTWARE, ROUTE_STAFF_AUGMENTATION } from 'shared/paths';

export const TabOffer = ({ isActive }: { isActive: boolean }) => {
  const isMobile = useBreakpoint('md');
  const isLg = useBreakpoint('lg');
  const { setHeaderMenuIsOpen } = useTheme();

  return (
    <Common.Div
      mb={4}
      flex={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      ml={isLg ? 0 : 6.5}
      hidden={!isActive}
      gap="16px"
    >
      <ClickableList
        flex="column"
        gap="8px"
        mt={4}
        onClick={() => {
          navigate(ROUTE_SOFTWARE);
          setHeaderMenuIsOpen(false);
        }}
      >
        <StyledB2 mb={2}>Software</StyledB2>
        <StyledB4>Web Apps</StyledB4>
        <StyledB4>Mobile Apps</StyledB4>
        <StyledB4>Admin panels with advanced data analytics</StyledB4>
        <StyledB4>Payment Integration</StyledB4>
        <StyledB4>UX / UI design</StyledB4>
        <StyledB4>Quality Assurance</StyledB4>
      </ClickableList>

      <ClickableList
        flex="column"
        gap="8px"
        mt={4}
        onClick={() => {
          navigate(ROUTE_DEEPTECH);
          setHeaderMenuIsOpen(false);
        }}
      >
        <StyledB2 mb={2}>Deep Tech</StyledB2>
        <StyledB4>Machine learning</StyledB4>
        <StyledB4>Data sets & Neural networks training</StyledB4>
        <StyledB4>Image & AI Processing</StyledB4>
        <StyledB4>Sound and Voice AI processing</StyledB4>
        <StyledB4>GPT solutions</StyledB4>
      </ClickableList>

      <ClickableList
        flex="column"
        gap="8px"
        mt={4}
        onClick={() => {
          navigate(ROUTE_HARDWARE);
          setHeaderMenuIsOpen(false);
        }}
      >
        <StyledB2 mb={2}>Hardware</StyledB2>
        <StyledB4>Electronics design</StyledB4>
        <StyledB4>Hardware prototyping</StyledB4>
        <StyledB4>Firmware & embedded software development</StyledB4>
        <StyledB4>Wireless connectivity</StyledB4>
      </ClickableList>

      <ClickableList
        flex="column"
        gap="8px"
        mt={4}
        onClick={() => {
          navigate(ROUTE_STAFF_AUGMENTATION);
          setHeaderMenuIsOpen(false);
        }}
      >
        <StyledB2 mb={2}>Staff augmentation</StyledB2>
        <StyledB4>Managed teams</StyledB4>
        <StyledB4>Outsourcing</StyledB4>
        <StyledB4>US offshore software development</StyledB4>
        <StyledB4>Development for startups</StyledB4>
      </ClickableList>
    </Common.Div>
  );
};

const StyledB2 = styled(B2)`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
`;

const StyledB4 = styled(B4)`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
`;
