import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  const [first, second] = data;
  const [firstTitle, firstContent] = first;
  const [secondTitle, secondContent] = second;

  return (
    <Wrapper>
      <HeaderText>How Codahead’s Approach Stands Out</HeaderText>
      <Text>{firstTitle}</Text>
      <Content>
        {firstContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>

      <Text>{secondTitle}</Text>
      <Content>
        {secondContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  [
    'Our Approach to Firmware Development:',
    [
      {
        title: 'Collaborative Design',
        sub: 'We work closely with your team to align with your project vision, goals, and hardware specifications.',
      },
      {
        title: 'Agile Iterations',
        sub: 'Our process focuses on iterative development, allowing for ongoing optimization and flexibility.',
      },
      {
        title: 'Cutting-Edge Technology',
        sub: 'We leverage the latest tools and frameworks to build efficient and forward-thinking embedded solutions.',
      },
    ],
  ],
  [
    'Traditional Development Models:',
    [
      {
        title: 'Limited Integration',
        sub: 'Hardware and firmware are often developed in silos, leading to mismatches and inefficiencies.',
      },
      {
        title: 'Lengthy Development Times',
        sub: 'Traditional approaches lack the agility needed to meet fast-paced market demands.',
      },
      {
        title: 'High Costs for Revisions',
        sub: 'Adjustments and updates often require costly rework, delaying the final product.',
      },
    ],
  ],
] as const;

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin: 58px auto 23px auto;
  width: 100%;
  justify-items: center;
  max-width: 1000px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
