export const webAppsData = [
  [
    'Custom Web Application Development',
    '- build unique, user-focused web applications tailored to your business goals and challenges.',
  ],
  [
    'Progressive Web Apps (PWAs)',
    '- deliver app-like experiences on the web with PWAs, ensuring speed, responsiveness, and offline functionality.',
  ],
  [
    'Enterprise Web Development',
    '- develop scalable, secure enterprise-grade web apps for managing operations, streamlining workflows, and driving efficiency.',
  ],
  [
    'eCommerce Solutions',
    '- create robust, feature-rich eCommerce platforms designed to convert visitors into loyal customers.',
  ],
  [
    'Third-Party Integrations',
    '- seamlessly connect your web app with external systems, APIs, and tools to enhance functionality and ensure smooth workflows.',
  ],
  [
    'UI/UX Design and Optimization',
    '- combine intuitive design with exceptional user experiences to keep users engaged and coming back.',
  ],
  ['Maintenance and Support', '- keep your web app running flawlessly with our proactive support and regular updates.'],
];
