import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  return (
    <Wrapper>
      <HeaderText>Full-Cycle Development Teams</HeaderText>
      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'DevOps Engineers',
    sub: 'DevOps engineers focus on automation, deployment, and continuous integration/continuous delivery (CI/CD), ensuring efficient and reliable development workflows and system stability.',
  },
  {
    title: 'Quality Assurance (QA) Engineers',
    sub: 'QA engineers handle testing for functionality, performance, and security. They use manual and automated testing methods to ensure robust and bug-free software.',
  },
  {
    title: 'UI/UX Designers',
    sub: 'User interface and experience designers craft intuitive layouts and workflows, prioritizing user engagement and satisfaction by focusing on usability and aesthetics.',
  },
  {
    title: 'Data Engineers',
    sub: 'Data engineers manage data pipelines and processing, while data scientists analyze data to provide insights. Both are essential for data-driven projects and analytics.',
  },
  {
    title: 'Cloud Engineers',
    sub: 'Experts in cloud platforms like AWS, Azure, and Google Cloud, they ensure smooth deployment, scalability, and security of cloud-based applications.',
  },
  {
    title: 'Project Managers',
    sub: 'Project managers oversee project planning and execution, while Scrum Masters ensure Agile methodology practices are followed, keeping teams organized and productive.',
  },
  {
    title: 'AI & ML Engineers',
    sub: 'ML and AI engineers build and deploy intelligent systems, handling algorithms, data models, and deep learning applications.',
  },
  {
    title: 'Embedded software experts',
    sub: 'Design embedded software architectures, develop firmware, validate systems, and provide ongoing support for lasting reliability.',
  },
  {
    title: 'Mobile App Developers',
    sub: 'Proficient in mobile platforms, including Flutter (cross-platform), Swift (iOS), and Kotlin (Android), delivering high-quality apps optimized for mobile devices.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
