import React from 'react';
import styled from 'styled-components';
import iconChart from 'assets/images/_icons/iconChart.svg';
import iconClipboard from 'assets/images/_icons/iconClipboard.svg';
import iconFileCheck from 'assets/images/_icons/iconFileCheck.svg';
import iconLock from 'assets/images/_icons/iconLock.svg';
import iconScale from 'assets/images/_icons/iconScale.svg';
import iconSliders from 'assets/images/_icons/iconSliders.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Data Sets & Neural Networks Training?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconChart,
    title: 'Expert-Led Training',
    sub: 'Experienced data scientists and AI specialists provide in-depth training to ensure that your models and data sets are built to perform and scale.',
  },
  {
    icon: iconSliders,
    title: 'Tailored to Your Needs',
    sub: 'Focus on your specific business goals, offering customized training that aligns with your project requirements and industry needs.',
  },
  {
    icon: iconClipboard,
    title: 'Proven Training Methodology',
    sub: 'Employ cutting-edge methodologies and best practices, ensuring that your neural networks are not only accurate but also capable of continuous learning.',
  },
  {
    icon: iconScale,
    title: 'Scalable Solutions',
    sub: 'Whether you’re building a small model or an enterprise-level AI system, our training can scale with your business needs, ensuring long-term success.',
  },
  {
    icon: iconFileCheck,
    title: 'Fast and Efficient Learning',
    sub: 'Techniques like transfer learning to significantly reduce training time, allowing for faster deployment and faster innovation cycles.',
  },
  {
    icon: iconLock,
    title: 'Data Privacy and Compliance',
    sub: 'All of our training services adhere to the highest standards of data security and compliance, ensuring that your data and models remain secure.',
  },
];
