import React from 'react';
import styled from 'styled-components';
import iconDatabase from 'assets/images/_icons/iconDatabase.svg';
import iconSettings from 'assets/images/_icons/iconSettings.svg';
import iconScale2 from 'assets/images/_icons/iconScale2.svg';
import iconGroup from 'assets/images/_icons/iconGroup.svg';
import iconDataflow2 from 'assets/images/_icons/iconDataflow2.svg';
import iconKey from 'assets/images/_icons/iconKey.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for Admin Panel Development?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconDatabase,
    title: 'Data-Driven Decision Making',
    sub: 'We integrate robust analytics tools to empower you with insights that fuel growth and innovation.',
  },
  {
    icon: iconSettings,
    title: 'Customizable Solutions',
    sub: 'Every business is unique—our admin panels are fully customizable to align with your processes and goals.',
  },
  {
    icon: iconScale2,
    title: 'Scalable Infrastructure',
    sub: 'Whether you’re managing a small team or a global operation, our solutions grow with your business.',
  },
  {
    icon: iconGroup,
    title: 'User-Friendly Interfaces',
    sub: 'Streamlined designs ensure your team spends less time learning the tool and more time making an impact.',
  },
  {
    icon: iconDataflow2,
    title: 'Integration with Existing Systems',
    sub: 'Seamlessly connect your admin panel with CRM, ERP or other enterprise tools for unified data management.',
  },
  {
    icon: iconKey,
    title: 'Focus on Security',
    sub: 'We implement advanced security measures to protect sensitive business data.',
  },
];
