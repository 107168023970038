import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  const [first, second] = data;
  const [firstTitle, firstContent] = first;
  const [secondTitle, secondContent] = second;

  return (
    <Wrapper>
      <HeaderText>How We Work – Codahead’s Approach vs. Traditional Development</HeaderText>
      <Text>{firstTitle}</Text>
      <Content>
        {firstContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>

      <Text>{secondTitle}</Text>
      <Content>
        {secondContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  [
    'Our Approach:',
    [
      {
        title: 'Data-Driven Insights',
        sub: 'We analyze your data to uncover valuable insights and opportunities for machine learning applications.',
      },
      {
        title: 'Proactive Collaboration',
        sub: 'Our team partners with you to align AI solutions with your business vision and goals.',
      },
      {
        title: 'Continuous Learning Models',
        sub: 'We design models that evolve with new data, keeping your systems competitive and effective.',
      },
    ],
  ],
  [
    'Traditional Methods:',
    [
      {
        title: 'Generic Solutions',
        sub: 'One-size-fits-all models lack the flexibility and precision to address unique business needs.',
      },
      {
        title: 'Long Development Cycles',
        sub: 'Traditional approaches often result in delays and outdated solutions upon release.',
      },
    ],
  ],
] as const;

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin: 58px auto 23px auto;
  width: 100%;
  justify-items: center;
  max-width: 1000px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
