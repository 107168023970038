import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  return (
    <Wrapper>
      <HeaderText>Benefits of Our Advanced Admin Panels</HeaderText>
      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'Improved Operational Efficiency',
    sub: 'Manage workflows, monitor performance, and automate repetitive tasks—all from a single interface.',
  },
  {
    title: 'Enhanced Decision-Making',
    sub: 'Make data-driven decisions with access to real-time analytics and actionable insights.',
  },
  {
    title: 'Reduced Complexity',
    sub: 'Replace scattered tools and processes with a unified admin panel that simplifies management.',
  },
  {
    title: 'Increased Scalability',
    sub: 'Adapt your panel to handle growing data volumes and expanding operational needs.',
  },
  {
    title: 'Secure Access Control',
    sub: 'Ensure sensitive data is accessible only to authorized users with role-based permissions.',
  },
  {
    title: 'Competitive Edge',
    sub: 'Leverage analytics and automation to stay ahead in your industry.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin-top: 50px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 2.19;
  max-width: 720px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
