exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-tsx": () => import("./../../../src/pages/ai.tsx" /* webpackChunkName: "component---src-pages-ai-tsx" */),
  "component---src-pages-blog-confirm-error-tsx": () => import("./../../../src/pages/blog-confirm-error.tsx" /* webpackChunkName: "component---src-pages-blog-confirm-error-tsx" */),
  "component---src-pages-blog-confirm-success-tsx": () => import("./../../../src/pages/blog-confirm-success.tsx" /* webpackChunkName: "component---src-pages-blog-confirm-success-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-deep-tech-data-sets-neural-networks-training-tsx": () => import("./../../../src/pages/deep-tech/data-sets-neural-networks-training.tsx" /* webpackChunkName: "component---src-pages-deep-tech-data-sets-neural-networks-training-tsx" */),
  "component---src-pages-deep-tech-gpt-tsx": () => import("./../../../src/pages/deep-tech/gpt.tsx" /* webpackChunkName: "component---src-pages-deep-tech-gpt-tsx" */),
  "component---src-pages-deep-tech-image-ai-processing-tsx": () => import("./../../../src/pages/deep-tech/image-ai-processing.tsx" /* webpackChunkName: "component---src-pages-deep-tech-image-ai-processing-tsx" */),
  "component---src-pages-deep-tech-index-tsx": () => import("./../../../src/pages/deep-tech/index.tsx" /* webpackChunkName: "component---src-pages-deep-tech-index-tsx" */),
  "component---src-pages-deep-tech-machine-learning-tsx": () => import("./../../../src/pages/deep-tech/machine-learning.tsx" /* webpackChunkName: "component---src-pages-deep-tech-machine-learning-tsx" */),
  "component---src-pages-deep-tech-sound-and-voice-ai-processing-tsx": () => import("./../../../src/pages/deep-tech/sound-and-voice-ai-processing.tsx" /* webpackChunkName: "component---src-pages-deep-tech-sound-and-voice-ai-processing-tsx" */),
  "component---src-pages-european-funds-tsx": () => import("./../../../src/pages/european-funds.tsx" /* webpackChunkName: "component---src-pages-european-funds-tsx" */),
  "component---src-pages-hardware-electronics-design-tsx": () => import("./../../../src/pages/hardware/electronics-design.tsx" /* webpackChunkName: "component---src-pages-hardware-electronics-design-tsx" */),
  "component---src-pages-hardware-firmware-embedded-software-development-tsx": () => import("./../../../src/pages/hardware/firmware-embedded-software-development.tsx" /* webpackChunkName: "component---src-pages-hardware-firmware-embedded-software-development-tsx" */),
  "component---src-pages-hardware-hardware-prototyping-tsx": () => import("./../../../src/pages/hardware/hardware-prototyping.tsx" /* webpackChunkName: "component---src-pages-hardware-hardware-prototyping-tsx" */),
  "component---src-pages-hardware-index-tsx": () => import("./../../../src/pages/hardware/index.tsx" /* webpackChunkName: "component---src-pages-hardware-index-tsx" */),
  "component---src-pages-hardware-wireless-connectivity-tsx": () => import("./../../../src/pages/hardware/wireless-connectivity.tsx" /* webpackChunkName: "component---src-pages-hardware-wireless-connectivity-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-job-offer-tsx": () => import("./../../../src/pages/job-offer.tsx" /* webpackChunkName: "component---src-pages-job-offer-tsx" */),
  "component---src-pages-join-us-tsx": () => import("./../../../src/pages/join-us.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-magazine-tsx": () => import("./../../../src/pages/magazine.tsx" /* webpackChunkName: "component---src-pages-magazine-tsx" */),
  "component---src-pages-other-inquiries-tsx": () => import("./../../../src/pages/other-inquiries.tsx" /* webpackChunkName: "component---src-pages-other-inquiries-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-software-admin-panels-with-advanced-data-analytics-tsx": () => import("./../../../src/pages/software/admin-panels-with-advanced-data-analytics.tsx" /* webpackChunkName: "component---src-pages-software-admin-panels-with-advanced-data-analytics-tsx" */),
  "component---src-pages-software-index-tsx": () => import("./../../../src/pages/software/index.tsx" /* webpackChunkName: "component---src-pages-software-index-tsx" */),
  "component---src-pages-software-mobile-apps-tsx": () => import("./../../../src/pages/software/mobile-apps.tsx" /* webpackChunkName: "component---src-pages-software-mobile-apps-tsx" */),
  "component---src-pages-software-payment-integration-tsx": () => import("./../../../src/pages/software/payment-integration.tsx" /* webpackChunkName: "component---src-pages-software-payment-integration-tsx" */),
  "component---src-pages-software-quality-assurance-tsx": () => import("./../../../src/pages/software/quality-assurance.tsx" /* webpackChunkName: "component---src-pages-software-quality-assurance-tsx" */),
  "component---src-pages-software-ux-ui-design-tsx": () => import("./../../../src/pages/software/ux-ui-design.tsx" /* webpackChunkName: "component---src-pages-software-ux-ui-design-tsx" */),
  "component---src-pages-software-web-apps-tsx": () => import("./../../../src/pages/software/web-apps.tsx" /* webpackChunkName: "component---src-pages-software-web-apps-tsx" */),
  "component---src-pages-staff-augmentation-development-for-startups-tsx": () => import("./../../../src/pages/staff-augmentation/development-for-startups.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-development-for-startups-tsx" */),
  "component---src-pages-staff-augmentation-index-tsx": () => import("./../../../src/pages/staff-augmentation/index.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-index-tsx" */),
  "component---src-pages-staff-augmentation-managed-teams-tsx": () => import("./../../../src/pages/staff-augmentation/managed-teams.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-managed-teams-tsx" */),
  "component---src-pages-staff-augmentation-outsourcing-tsx": () => import("./../../../src/pages/staff-augmentation/outsourcing.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-outsourcing-tsx" */),
  "component---src-pages-staff-augmentation-us-offshore-software-development-tsx": () => import("./../../../src/pages/staff-augmentation/us-offshore-software-development.tsx" /* webpackChunkName: "component---src-pages-staff-augmentation-us-offshore-software-development-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-sections-blog-post-page-post-page-tsx": () => import("./../../../src/sections/Blog/PostPage/PostPage.tsx" /* webpackChunkName: "component---src-sections-blog-post-page-post-page-tsx" */),
  "component---src-sections-our-projects-project-section-tsx": () => import("./../../../src/sections/OurProjects/ProjectSection.tsx" /* webpackChunkName: "component---src-sections-our-projects-project-section-tsx" */)
}

