import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/StaffAugmentation/DevelopmentForStartups/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/StaffAugmentation/DevelopmentForStartups/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/StaffAugmentation/DevelopmentForStartups/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/StaffAugmentation/DevelopmentForStartups/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Staff augmentation"
        meta={[
          {
            name: 'keywords',
            content: 'managed teams, outsourcing, US offshore software development, development for startups',
          },
        ]}
        description={
          'Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your goals faster and more efficiently. Looking for the right fit? Look no further!'
        }
      />

      <CommonMargin>
        <StyledSection
          title="Development for startups"
          variant="secondary"
          id="Development for startups"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            Empowering Start-ups with Agile, Scalable, and Custom Development Solutions. Launching a start-up is a
            journey filled with vision, innovation, and challenges. With a client base that’s primarily composed of
            start-ups and mid-size companies, we’re dedicated to helping businesses get on their feet, grow bigger, and
            make a powerful impact within their industries.
            <br />
            <br />
            Our development services provide the technical expertise, flexibility, and rapid iteration needed to
            transform ideas into impactful products. From MVPs to full-scale solutions, we adapt as you grow, leveraging
            cutting-edge technology and agile methodologies to keep you competitive and resilient in a fast-paced
            market.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
