import React from 'react';
import styled from 'styled-components';
import iconCube from 'assets/images/_icons/iconCube.svg';
import iconPiggyBank from 'assets/images/_icons/iconPiggyBank.svg';
import iconRefresh from 'assets/images/_icons/iconRefresh2.svg';
import iconStars from 'assets/images/_icons/iconStars.svg';
import iconLayout from 'assets/images/_icons/iconLayout.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Our Hardware Prototyping Services?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconCube,
    title: 'End-to-End Support for Faster Prototyping',
    sub: 'We provide comprehensive services from initial design to functional prototype, ensuring every step is efficient and focused on getting results quickly.',
  },
  {
    icon: iconPiggyBank,
    title: 'Cost-Efficient, Scalable Solutions',
    sub: 'Our prototyping process is designed to be flexible and cost-effective, allowing for changes and iterations without unnecessary expenses.',
  },
  {
    icon: iconStars,
    title: 'Skilled Engineers and Advanced Technology',
    sub: 'Our team leverages the latest tools in CAD, PCB design, and 3D printing to build prototypes that meet the highest quality standards.',
  },
  {
    icon: iconRefresh,
    title: 'Iterative, Agile Process for Rapid Refinement',
    sub: 'We use an agile approach to continuously refine and improve prototypes, ensuring they meet your expectations and are production-ready.',
  },
  {
    icon: iconLayout,
    title: 'Focus on Practical, Production-Ready Prototypes',
    sub: 'We design with the end in mind, creating prototypes that are not only functional but also practical and feasible for mass production.',
  },
];
