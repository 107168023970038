import React from 'react';
import styled from 'styled-components';
import iconAward from 'assets/images/_icons/iconAward.svg';
import iconIntersect from 'assets/images/_icons/iconIntersect.svg';
import iconLink from 'assets/images/_icons/iconLink.svg';
import iconPhone from 'assets/images/_icons/iconPhone.svg';
import iconTool from 'assets/images/_icons/iconTool.svg';
import iconUserCheck from 'assets/images/_icons/iconUserCheck.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for Mobile App Development?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconPhone,
    title: 'Deep Expertise in Mobile Development',
    sub: 'Experienced developers know how to create apps that are both innovative and robust, ensuring top performance and user satisfaction.',
  },
  {
    icon: iconTool,
    title: 'Cutting-Edge Tools and Technologies',
    sub: 'Leverage the latest tech stacks and frameworks, including Flutter, Kotlin, Swift and React Native, to deliver apps that stand out.',
  },
  {
    icon: iconIntersect,
    title: 'Seamless Cross-Platform Solutions',
    sub: 'With our cross-platform capabilities, you get apps that perform consistently on iOS and Android, reducing time and cost.',
  },
  {
    icon: iconUserCheck,
    title: 'User-Centric Design',
    sub: 'Every app we build is designed with your end-users in mind, combining functionality with intuitive navigation.',
  },
  {
    icon: iconLink,
    title: 'Scalable and Future-Ready',
    sub: 'Whether you’re starting small or scaling big, our apps are built to grow with your business and adapt to future needs.',
  },
  {
    icon: iconAward,
    title: 'Proven Success with Startups and Enterprises',
    sub: 'From startups launching MVPs to enterprises scaling operations, we’ve delivered mobile solutions that drive results.',
  },
];
