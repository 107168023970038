import React from 'react';
import styled from 'styled-components';
import iconCalculator from 'assets/images/_icons/iconCalculator.svg';
import iconChart from 'assets/images/_icons/iconChart2.svg';
import iconUsersPlus from 'assets/images/_icons/iconUsersPlus.svg';
import iconPresentation from 'assets/images/_icons/iconPresentation.svg';
import iconAward from 'assets/images/_icons/iconAward.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Our Outsourcing Services?</Title>
      <Content>
        {data.map(({ icon, sub, title }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconAward,
    title: 'Proven Track Record Across Industries',
    sub: 'over 16 years of experience in IT outsourcing, where successfully partnered with start-ups, mid-size companies, and enterprises, offering strategic support and tailored solutions.',
  },
  {
    icon: iconCalculator,
    title: 'Customized Engagement Models',
    sub: 'from project-based outsourcing to dedicated teams, our flexible engagement options allow you to choose the model that best suits your needs, maximizing ROI and efficiency.',
  },
  {
    icon: iconUsersPlus,
    title: 'Seamless integration with your team',
    sub: 'Our outsourcing services are designed to integrate seamlessly with your internal team and workflows, ensuring smooth collaboration and a unified approach to project goals.',
  },
  {
    icon: iconPresentation,
    title: 'Cutting-Edge Tech and Best Practices',
    sub: 'We stay at the forefront of IT trends, bringing you the latest tools, technologies, and methodologies to maintain a competitive advantage in a rapidly evolving market.',
  },
  {
    icon: iconChart,
    title: 'Commitment to Long-Term Success',
    sub: 'We build lasting partnerships, supporting your projects beyond delivery with ongoing maintenance, updates, and support to drive sustainable growth.',
  },
];
