import React from 'react';
import styled from 'styled-components';
import iconClock from 'assets/images/_icons/iconClock.svg';
import iconCogwheel from 'assets/images/_icons/iconCogwheel.svg';
import iconGraduation from 'assets/images/_icons/iconGraduation.svg';
import iconSliders from 'assets/images/_icons/iconSliders2.svg';
import iconMicroscope from 'assets/images/_icons/iconMicroscope.svg';
import iconKey from 'assets/images/_icons/iconKey.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Image AI Processing?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconGraduation,
    title: 'Expert Engineers',
    sub: 'AI specialists bring deep expertise in computer vision and image processing, ensuring that your solutions are both cutting-edge and reliable.',
  },
  {
    icon: iconSliders,
    title: 'Tailored Solutions',
    sub: 'We understand that every business has unique needs. That’s why we customize our image AI processing services to match your specific industry and project requirements.',
  },
  {
    icon: iconMicroscope,
    title: 'High Accuracy',
    sub: 'AI models are trained on vast, high-quality datasets, ensuring accurate image recognition, detection, and analysis with minimal error.',
  },
  {
    icon: iconClock,
    title: 'Fast Processing',
    sub: 'Deliver solutions that can handle large volumes of images quickly, whether you need real-time processing or batch analysis, all while scaling seamlessly with your business growth.',
  },
  {
    icon: iconCogwheel,
    title: 'Integration Support',
    sub: 'Solutions integrate easily into your existing systems and workflows, ensuring smooth deployment without disruptions to your operations.',
  },
  {
    icon: iconKey,
    title: 'Security & Privacy',
    sub: 'Industry-best practices to ensure the security and confidentiality of your image data, meeting regulatory standards for data protection.',
  },
];
