import React, { Fragment } from 'react';
import { Links } from 'shared/links';
import shortId from 'shortid';
import { NavContainer, NavLink, NavText } from './Navigation.styled';

const isBrowser = typeof window !== 'undefined';

export interface NavigationProps {}

export const Navigation = ({}: NavigationProps) => {
  // ignore last '/' element if exists
  const urlPaths = isBrowser ? window.location.pathname.replace(/\/$/, '').split('/') : [];

  return (
    <NavContainer>
      {urlPaths.map((link, index) => {
        const page = Links.find((l) => l.link === `/${link}`);
        const isCurrent = index === urlPaths.length - 1;

        if (page)
          return (
            <Fragment key={shortId.generate()}>
              {isCurrent ? (
                <NavText isActive>{page.name}</NavText>
              ) : (
                <NavLink isActive={isCurrent} key={shortId.generate()} to={`/${link}`}>
                  {` ${page.name} `}
                </NavLink>
              )}
              <NavText>{!isCurrent ? '>' : ''}</NavText>
            </Fragment>
          );
      })}
    </NavContainer>
  );
};
