import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  return (
    <Wrapper>
      <HeaderText>Codahead's approach</HeaderText>
      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'Flexibility',
    sub: 'Scale teams dynamically based on project needs, ensuring efficiency and adaptability.',
  },
  {
    title: 'Transparency',
    sub: 'Clear updates, open communication, and shared dashboards keep you informed at every stage.',
  },
  {
    title: 'Quality',
    sub: 'High standards with specialized experts, including PhD students and westernized-minded, highly trained engineers.',
  },
  {
    title: 'Onboarding Speed',
    sub: 'Teams onboarded and aligned with your workflow in days, not weeks.',
  },
  {
    title: 'Security',
    sub: 'Robust protocols ensure data safety and compliance with industry standards.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
