export const developmentForStartupsData = [
  [
    'MVP development and rapid prototyping',
    '- creating minimum viable products (MVPs) swiftly, enabling you to test ideas in the market and gain valuable feedback to iterate and grow.',
  ],
  [
    'Agile and Scalable Solutions',
    "- get scalable, agile development to meet your start-up's changing demands, empowering you to launch fast, pivot, and expand as needed.",
  ],
  [
    'Specialized Skills and Emerging Tech',
    '- benefit from the latest in tech—like AI, IoT, and blockchain—alongside specialized developers who bring innovative thinking to your project.',
  ],
  [
    'Cost-Effective Development Models',
    '- start-ups often need to optimize costs. Our tailored development services offer flexible engagement models to help you make the most of your budget without compromising quality.',
  ],
  [
    'Fast Market Entry Reply',
    '- streamline development to reduce time-to-market, helping your start-up establish a presence and gain traction early in the game.',
  ],
  [
    'Long-Term Partnership and Support',
    '- ongoing maintenance, updates, and support, ensuring your product remains robust and relevant as your start-up evolves.',
  ],
];
