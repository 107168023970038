export const imageAiProcessingData = [
  [
    'Object Detection and Classification',
    '- detect and classify objects in images or videos, enabling applications like surveillance, retail inventory management, and autonomous vehicles.',
  ],
  [
    'Facial Recognition and Emotion Detection',
    '- accurately identify faces and analyze emotional states for security, customer experience enhancements, and personalized marketing strategies.',
  ],
  [
    'Image Segmentation and Semantic Analysis',
    '- break down images into their constituent parts to detect boundaries and objects, enhancing medical imaging, agricultural monitoring, and more.',
  ],
  [
    'Automated Image Tagging and Indexing',
    '- automatically tag and categorize large volumes of images to improve searchability and content organization in media, e-commerce, and digital archives.',
  ],
  [
    'Optical Character Recognition (OCR)',
    '- extract text from images or scanned documents, enabling digital workflows for document management, legal work, and data processing.',
  ],
  [
    'Image Quality Enhancement',
    '- improve image resolution and quality using AI-driven algorithms for use in media, healthcare imaging, and other fields requiring high-quality visuals.',
  ],
];
