import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  return (
    <Wrapper>
      <HeaderText>How Codahead Brings Your App to Life</HeaderText>
      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'Collaborative Discovery Phase',
    sub: 'We start by understanding your goals, target audience, and unique challenges to create a tailored development roadmap.',
  },
  {
    title: 'Cutting-Edge Tech Stack',
    sub: 'Using technologies like Flutter, Kotlin, Swift, and React Native, we deliver apps that are fast, reliable, and future-ready.',
  },
  {
    title: 'Launch and Beyond',
    sub: 'After deployment, we provide maintenance, updates, and support to keep your app thriving in a competitive market.',
  },
  {
    title: 'Agile Development Process',
    sub: 'Iterative development, we keep you involved at every stage, ensuring flexibility and quick adjustments.',
  },
  {
    title: 'Rigorous Testing',
    sub: 'Our apps undergo comprehensive QA testing to ensure they’re bug-free, secure, and optimized for real-world conditions.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin-top: 50px;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 16px;
  font-weight: 300;
  line-height: 2.19;
  max-width: 720px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
