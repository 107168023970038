import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { B2 } from 'styles/Typography.styled';

export const NavContainer = styled.nav(
  () =>
    css`
      position: absolute;
      top: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    `
);

export const NavLink = styled(Link)<{ isActive?: boolean }>(
  ({ theme: { colors, fontSizes, fontWeight }, isActive }) =>
    css`
      color: ${isActive ? colors.hoverBlue : colors.white};
      font-size: ${fontSizes.f14};
      font-weight: ${fontWeight.medium};
      line-height: 18px;
      text-decoration: none;
      padding: 0;
      text-transform: lowercase;

      &:hover {
        color: ${colors.hoverBlue};
      }
    `
);

export const NavText = styled(B2)<{ isActive?: boolean }>(
  ({ theme: { colors, fontSizes, fontWeight }, isActive }) =>
    css`
      color: ${isActive ? colors.hoverBlue : colors.white};
      font-size: ${fontSizes.f14};
      font-weight: ${fontWeight.medium};
      line-height: 18px;
      text-decoration: none;
      text-transform: lowercase;
    `
);
