import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { H3 } from 'styles/Typography.styled';
import { SPACING_PX } from 'styles/theme';

export const OfferText = styled(Common.Div)`
  max-width: 230px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.79;
  color: #f1f1f1;
`;

export const VerticalLine = styled(Common.Div)`
  border-right: 1px solid ${({ theme: { colors } }) => colors.white};
  height: 100%;
  max-height: 338px;
  margin-left: 18px;

  ${mediaQueries.md} {
    height: auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 96px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  margin: ${8 * SPACING_PX}px 0 ${2 * SPACING_PX}px 0;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
  }

  @media (max-width: 740px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  margin-left: 18px;
  border-left: 1px solid ${({ theme: { colors } }) => colors.white};
`;

const StyledH3 = styled(H3)`
  font-size: 24px;
  max-width: 280px;
  min-height: 60px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.body};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
`;

const StyledSection = styled(Section)`
  h2 {
    font-family: ${({ theme: { fontFamily } }) => fontFamily.body};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.f32};
  }
`;

export const DistinctBenefitsSection = () => {
  return (
    <StyledSection title="Distinct Benefits">
      <Wrapper>
        {data.map(({ title, sub }, idx) => (
          <Common.Div key={idx}>
            <Common.Div flex="row" gap="16px" alignItems="center" mb={3}>
              <StyledH3>{title}</StyledH3>
            </Common.Div>

            <Common.Div flex="row" gap="16px">
              <Item>
                <OfferText mt={2} mb={4}>
                  {sub}
                </OfferText>
              </Item>
            </Common.Div>
          </Common.Div>
        ))}
      </Wrapper>
    </StyledSection>
  );
};

const data = [
  {
    title: 'Iterative development gain',
    sub: `- development process with GPT-powered solutions that enhance coding efficiency, automate mundane tasks, and foster innovative breakthroughs.`,
  },
  {
    title: 'Streamlined workflow',
    sub: `- our GPT integrations reduce friction in everyday operations, automating repetitive processes and empowering your team to focus on high-impact work.`,
  },
  {
    title: 'Seamless team integration',
    sub: `- integrate AI effortlessly into your team dynamics. Whether it's customer support, development, or business strategy, our GPT solutions fit smoothly into your workflow without disrupting existing systems.`,
  },
  {
    title: 'Empowered AI customization',
    sub: `- we build tailored GPT solutions that adapt to your business's unique challenges and opportunities, giving you full control over AI performance and results.`,
  },
  {
    title: 'Strategic insight advancement',
    sub: `- unlock deep, data-driven insights. Our GPT implementations provide real-time analysis and recommendations, driving smarter business decisions.`,
  },
  {
    title: 'Effective GPT deployments',
    sub: `- from concept to execution, we ensure your GPT solution is seamlessly deployed, securely managed, and optimized for peak performance across your organization.`,
  },
];
