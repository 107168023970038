export const firmwareAndEmbeddedSoftwareDevelopmentData = [
  [
    'Custom Firmware Development',
    '- develop tailor-made firmware optimized for your specific hardware, ensuring seamless operation, enhanced performance, and maximum efficiency.',
  ],
  [
    'Embedded Software Design',
    '- low-level drivers to advanced application layers, we design embedded software that’s functional, secure, and ready to scale.',
  ],
  [
    'IoT device integration',
    '- our team specializes in enabling IoT connectivity, ensuring your devices communicate effectively with the cloud and other systems.',
  ],
  [
    'Real-Time Operating Systems (RTOS) Development',
    '- we deliver RTOS solutions for time-sensitive applications, ensuring precision and reliability in mission-critical environments.',
  ],
  [
    'Hardware and Firmware Co-Design',
    '- by designing hardware and firmware in parallel, we ensure optimal compatibility and performance, reducing development time and costs.',
  ],
  [
    'Testing, Validation, and Debugging',
    '- rigorous testing ensures that your firmware is free of bugs, secure, and performs flawlessly under various conditions.',
  ],
];
