export const managedTeamsData = [
  [
    'Autonomous, Cross-Functional Expertise',
    '- bring together expert developers, designers, and managers to cover all aspects of your project, ensuring seamless collaboration and continuous progress.',
  ],
  [
    'Project Management Style',
    '- we adapt to your project management tools and workflows, integrating smoothly so our team feels like an extension of yours from day one.',
  ],
  [
    'Scalable development',
    '- our teams can scale resources up or down as needed, ensuring flexibility for launches, feature updates, or increased demand.',
  ],
  [
    'Control and Transparency',
    '- enjoy full visibility and control with regular updates, accessible dashboards, and clear communication at every stage.',
  ],
  [
    'Cost-Efficient and Predictable Budgeting',
    '- with all expertise in one managed team, costs stay predictable, avoiding extra expenses from separate contractors.',
  ],
  [
    'Faster Time-to-Market',
    '- our cross-functional teams work in parallel, speeding up development to bring your product to market faster.',
  ],
];
