export const electronicsDesignData = [
  [
    'System Architecture and Circuit Design',
    '- design optimized, reliable system architectures and circuits that meet performance and efficiency standards while minimizing complexity.',
  ],
  [
    'Embedded Firmware Development',
    '- write customized firmware for embedded systems, ensuring seamless integration and responsiveness across all components.',
  ],
  [
    'PCB Layout and Prototyping',
    '- with our high-precision PCB design and rapid prototyping capabilities, we create boards that are cost-effective and tailored to specific industry requirements.',
  ],
  [
    'Testing and Validation',
    '- rigorous testing process ensures each product meets strict quality standards, reducing risk and ensuring reliable performance.',
  ],
  [
    'Certification and Compliance Support',
    '- regulatory certifications and compliance checks to make sure your product adheres to relevant industry standards for safety and efficiency.',
  ],
  [
    'Long-Term Maintenance and Support',
    '- post-launch, we offer ongoing support and updates, helping you keep products performing optimally in the field.',
  ],
];
