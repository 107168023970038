import React from 'react';
import styled from 'styled-components';
import iconCreditCard from 'assets/images/_icons/iconCreditCard.svg';
import iconFileLock from 'assets/images/_icons/iconFileLock.svg';
import iconHeartHand from 'assets/images/_icons/iconHeartHand.svg';
import iconMoney from 'assets/images/_icons/iconMoney.svg';
import iconTrophy from 'assets/images/_icons/iconTrophy.svg';
import iconUserImageCheck from 'assets/images/_icons/iconUserImageCheck.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for Payment Integration?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconMoney,
    title: 'Expertise in Leading Gateways',
    sub: 'From Stripe to Authorize.net, we have extensive experience integrating top payment platforms.',
  },
  {
    icon: iconFileLock,
    title: 'Focus on Security',
    sub: 'We adhere to PCI-DSS standards, ensuring your transactions and customer data remain secure.',
  },
  {
    icon: iconCreditCard,
    title: 'Customizable Solutions',
    sub: 'No matter your business model, we create payment systems tailored to your operations.',
  },
  {
    icon: iconUserImageCheck,
    title: 'Seamless User Experience',
    sub: 'Optimized for speed and ease of use, we enhance checkout experiences to reduce cart abandonment.',
  },
  {
    icon: iconHeartHand,
    title: 'End-to-End Support',
    sub: 'From initial setup to testing and ongoing maintenance, we’re with you at every step.',
  },
  {
    icon: iconTrophy,
    title: 'Proven Success',
    sub: "We've helped startups, SMBs, and enterprises implement payment systems that drive revenue and build trust.",
  },
];
