import React from 'react';
import styled from 'styled-components';
import iconAward from 'assets/images/_icons/iconAward2.svg';
import iconBook from 'assets/images/_icons/iconBook2.svg';
import iconCalendar from 'assets/images/_icons/iconCalendar2.svg';
import iconCircle from 'assets/images/_icons/iconCircle.svg';
import iconLock from 'assets/images/_icons/iconLock2.svg';
import iconSettings from 'assets/images/_icons/iconSettings2.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Sound and Voice AI Processing?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconBook,
    title: 'Cutting-Edge Expertise',
    sub: 'Latest sound and voice AI technologies, including neural networks, transformers, and advanced acoustic models.',
  },
  {
    icon: iconSettings,
    title: 'Custom Solutions for Every Industry',
    sub: 'From healthcare and automotive to smart homes and entertainment, we tailor solutions to suit your industry needs.',
  },
  {
    icon: iconAward,
    title: 'Focus on Accuracy and Reliability',
    sub: 'Robust testing and optimization, our systems achieve exceptional accuracy in real-world conditions.',
  },
  {
    icon: iconLock,
    title: 'Data Privacy and Compliance',
    sub: 'AI models adhere to strict privacy standards, ensuring sensitive audio data is secure and compliant with global regulations.',
  },
  {
    icon: iconCalendar,
    title: 'Accelerated Time-to-Market',
    sub: 'Streamline development processes to help you launch innovative voice and sound products faster, maintaining a competitive edge.',
  },
  {
    icon: iconCircle,
    title: 'Integrated Approach',
    sub: 'Combining AI expertise with software and hardware development, we deliver cohesive, end-to-end solutions.',
  },
];
