import React from 'react';
import styled from 'styled-components';
import iconBook from 'assets/images/_icons/iconBook.svg';
import iconCalendar from 'assets/images/_icons/iconCalendar.svg';
import iconRefresh from 'assets/images/_icons/iconRefresh.svg';
import iconScale from 'assets/images/_icons/iconScale3.svg';
import iconShield from 'assets/images/_icons/iconShield.svg';
import iconSkew from 'assets/images/_icons/iconSkew.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Machine Learning Services?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconBook,
    title: 'Expertise Across Domains',
    sub: 'Our team brings extensive experience in diverse industries, including healthcare, fintech, retail, and manufacturing.',
  },
  {
    icon: iconSkew,
    title: 'Custom-Tailored Solutions',
    sub: 'We create machine learning models tailored to your specific business goals, ensuring maximum impact and relevance.',
  },
  {
    icon: iconScale,
    title: 'Scalable Architecture',
    sub: 'Build ML solutions that grow with your business, from small-scale automation to enterprise-level AI systems.',
  },
  {
    icon: iconRefresh,
    title: 'Agile and Collaborative Approach',
    sub: 'We work closely with your team, ensuring a seamless integration of machine learning into your workflows.',
  },
  {
    icon: iconShield,
    title: 'Data Security and Compliance',
    sub: 'We prioritize the security of your data, adhering to global compliance standards to keep your information safe.',
  },
  {
    icon: iconCalendar,
    title: 'Accelerated Time-to-Market',
    sub: 'With rapid prototyping and agile development, we help you launch AI-powered solutions faster without compromising on quality.',
  },
];
