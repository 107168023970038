import React from 'react';
import styled from 'styled-components';
import iconCheck from 'assets/images/_icons/iconCheck.svg';
import iconClipboardCheck from 'assets/images/_icons/iconClipboardCheck.svg';
import iconDiamond from 'assets/images/_icons/iconDiamond.svg';
import iconLightBulb from 'assets/images/_icons/iconLightBulb.svg';
import iconTarget from 'assets/images/_icons/iconTarget.svg';
import iconVerified from 'assets/images/_icons/iconVerified.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for QA?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconTarget,
    title: 'Tailored QA Strategies',
    sub: 'We customize our testing processes to match your project’s unique requirements.',
  },
  {
    icon: iconLightBulb,
    title: 'Cutting-Edge Tools and Expertise',
    sub: 'Leverage modern tools like Selenium, Appium, and JIRA, combined with our team’s expertise, for flawless results.',
  },
  {
    icon: iconCheck,
    title: 'End-to-End QA',
    sub: 'From planning to post-release testing, we provide comprehensive coverage for all your QA needs.',
  },
  {
    icon: iconVerified,
    title: 'Fast and Scalable Testing',
    sub: 'Our flexible approach adapts to your project’s pace and size, ensuring efficiency without compromising quality.',
  },
  {
    icon: iconClipboardCheck,
    title: 'Proactive Issue Resolution',
    sub: 'Identify and address potential problems early to save time, reduce costs, and protect your reputation.',
  },
  {
    icon: iconDiamond,
    title: 'Commitment to Excellence',
    sub: 'We adhere to industry standards and best practices to deliver QA that’s second to none.',
  },
];
