import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/DeepTech/DataSetsNeuralNetworksTraining/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Deeptech"
        meta={[
          {
            name: 'keywords',
            content:
              'machine learning consulting, codium ai, picture recognition app, microservices benefits, 3d avatars, application scalability, ai cybersecurity solutions, ai strategy consulting, virtual branch, bayesian network, small language models, fraud detection machine learning, neural architecture search, deep belief network, artificial immunity, adaptive ai, how to build an ai model, parameter efficient fine tuning, peft fine tuning, machine learning in marketing, machine learning advertising, react native app development services, react native debugger, latest react version, react-app-rewired, react and react native, react software, angular software, react native reanimated, react native gesture handler, react native ide, react native slider, react native bottom sheet, react-native-web, react animation, react-spring, react-native-screens, react switch, react accordion, react debugger, react modal, react-native-modal, react setstate callback, expo development build, react native for web, react-native-config, react native share, react native flatlist, react native image, jest react, jest npm, ai label generator, python microservices, ai consulting firms, discriminative ai, artificial intelligence consultant, ai in transportation, ai erp, ai in software testing, ai erp chatbot, ai in entertainment',
          },
        ]}
        description={
          "Unlock the potential of your business with our machine learning consulting services. From developing a picture recognition app to implementing fraud detection machine learning, we help organizations leverage AI cybersecurity solutions and enhance their AI strategy consulting. Explore how microservices benefits can improve your application's scalability and efficiency."
        }
      />

      <CommonMargin>
        <StyledSection
          title="Data sets & Neural networks training"
          variant="secondary"
          id="Data sets & Neural networks training"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            From speech recognition to advanced sound analysis, we use AI to create seamless, engaging experiences that
            boost efficiency and open up new possibilities. Whether you're developing voice assistants, audio analytics
            tools, or sound-powered IoT devices, we’re here to bring your ideas to life. Let’s make some noise and
            create something amazing together!
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
