import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/StaffAugmentation/Outsourcing/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/StaffAugmentation/Outsourcing/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/StaffAugmentation/Outsourcing/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/StaffAugmentation/Outsourcing/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Staff augmentation"
        meta={[
          {
            name: 'keywords',
            content: 'managed teams, outsourcing, US offshore software development, development for startups',
          },
        ]}
        description={
          'Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your goals faster and more efficiently. Looking for the right fit? Look no further!'
        }
      />

      <CommonMargin>
        <StyledSection title="Outsourcing" variant="secondary" id="Outsourcing" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            Our team of friendly PhD experts and seasoned professionals are ready to partner with you on your next
            project. Whether you need support with custom software implementation, hardware design, or technical
            strategy, we’re excited to lend our expertise to make your vision a reality. When you work with us, you’re
            not just getting a service, you’re gaining a team of dedicated specialists who genuinely care about your
            success. We thrive on collaboration and are committed to providing insights that help you navigate
            challenges with ease.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
