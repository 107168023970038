export const uiUxDesignData = [
  [
    'User Research and Persona Development',
    '- gain insights into your audience with in-depth research, helping us craft experiences that resonate.',
  ],
  [
    'Wireframing and Prototyping',
    '- visualize your product’s journey with interactive prototypes that streamline development and reduce risk.',
  ],
  ['Custom UI Design', '- unique, visually captivating designs tailored to your brand’s identity and goals.'],
  ['Responsive and Adaptive Design', '- seamless user experiences across all devices—desktop, tablet, or mobile.'],
  ['UX Audits and Optimization', '- enhance performance by analyzing user behavior and improving existing designs.'],
  ['Accessibility-First Approach', '- ensure inclusivity with designs that meet global accessibility standards.'],
  [
    'Animation and Micro-Interactions',
    '- add polish with subtle animations that guide users and elevate functionality.',
  ],
];
