export const adminPanelsData = [
  [
    'Custom Admin Panel Design',
    '- tailor-made interfaces to meet the unique needs of your business, ensuring intuitive navigation and usability.',
  ],
  [
    'Advanced Data Visualization',
    '- transform raw data into actionable insights with real-time dashboards and visualizations.',
  ],
  [
    'Integrated Analytics Tools',
    '- leverage built-in analytics capabilities to track KPIs, predict trends, and drive informed decisions.',
  ],
  ['Multi-Level User Access', '- secure admin panels with customizable permissions for different roles and teams.'],
  ['Real-Time Reporting', '- generate and access real-time reports to monitor performance and identify opportunities.'],
  [
    'Cloud and On-Premise Solutions',
    '- flexible hosting options to match your operational requirements and data security standards.',
  ],
];
