import React from 'react';
import styled from 'styled-components';
import iconUsers from 'assets/images/_icons/iconUsers.svg';
import iconDataFlow from 'assets/images/_icons/iconDataFlow.svg';
import iconPen from 'assets/images/_icons/iconPen.svg';
import iconScale from 'assets/images/_icons/iconScale.svg';
import iconTick from 'assets/images/_icons/iconTick.svg';
import iconCode from 'assets/images/_icons/iconCode.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for Web App Development?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconUsers,
    title: 'Experienced Developers',
    sub: 'Our team of developers has years of experience building web applications across industries, from startups to global enterprises.',
  },
  {
    icon: iconPen,
    title: 'Tailored Solutions',
    sub: 'We don’t do cookie-cutter apps. Every project is custom-built to match your specific business needs and user demands.',
  },
  {
    icon: iconDataFlow,
    title: 'Cutting-Edge Technology',
    sub: 'Leverage modern frameworks and tools, including React, Angular, Vue.js, Node.js, and more, to deliver fast, reliable web apps.',
  },
  {
    icon: iconScale,
    title: 'Scalability at Core',
    sub: 'Whether your app needs to handle thousands of users or millions, our solutions are designed to grow with your business.',
  },
  {
    icon: iconTick,
    title: 'Enhanced Security',
    sub: 'Protect your data and users with best-in-class security measures, ensuring compliance with industry standards.',
  },
  {
    icon: iconCode,
    title: 'End-to-End Development',
    sub: 'From concept to deployment and beyond, we handle every step of the process, so you can focus on your business.',
  },
];
