import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/StaffAugmentation/ManagedTeams/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/StaffAugmentation/ManagedTeams/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/StaffAugmentation/ManagedTeams/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/StaffAugmentation/ManagedTeams/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Staff augmentation"
        meta={[
          {
            name: 'keywords',
            content: 'managed teams, outsourcing, US offshore software development, development for startups',
          },
        ]}
        description={
          'Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your goals faster and more efficiently. Looking for the right fit? Look no further!'
        }
      />

      <CommonMargin>
        <StyledSection title="Managed teams" variant="secondary" id="Managed teams" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            Navigating complex projects requires not just technical skill, but a team that aligns seamlessly with your
            vision and processes. Our managed development team services provide dedicated software development teams
            tailored to your project’s unique demands. Whether you're scaling with staff augmentation, integrating
            specialized skills, or seeking a consulting partner, we ensure seamless collaboration and enhanced
            productivity for meaningful business impact.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
