import * as Common from 'components/_universal/Common';
import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { ROUTE_CONTACT } from 'shared/paths';
import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { mediaQueries } from 'shared/breakpoints';
import Deep2 from 'assets/images/Offer/deep2.png';

export const ImageWithTextSection = () => {
  return (
    <Wrapper>
      <Content>
        <StyledImage src={Deep2} />
        <Text>
          <Title>Start Your AI Training Journey Today</Title>
          <Subtitle>
            Ready to accelerate your AI initiatives? Whether you're looking to train a neural network from scratch or
            optimize your existing models, Codahead is here to help. Our data sets and neural network training solutions
            are designed to give you the tools you need to stay ahead of the curve. Contact us now and let’s unlock the
            true potential of AI together.
          </Subtitle>
          <Button onClick={() => navigate(ROUTE_CONTACT)}>
            let's start
            <img src={ArrowSvg} alt="exploreButton" />
          </Button>
        </Text>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 182px 156px 92px 156px;
  width: 100%;

  @media (max-width: 1100px) {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  padding: 22px 56px;

  @media (max-width: 1100px) {
    padding: 56px;
  }

  ${mediaQueries.md} {
    padding: 44px 0;
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 1.38;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 2.19;
  color: ${({ theme: { colors } }) => colors.white};
  margin-top: 35px;
`;

const Button = styled.button`
  color: ${({ theme: { colors } }) => colors.white};
  width: 255px;
  height: 45px;
  padding: 13px 17px 13px 28px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  border: none;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.light};
  outline: none;
  cursor: pointer;
  transition: 120ms ease opacity;

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledImage = styled(Common.Svg)`
  width: 445px;
  height: 100%;

  ${mediaQueries.sm} {
    width: 100%;
  }
`;
