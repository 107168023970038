export const hardwarePrototypingData = [
  [
    'Concept Development and Feasibility Analysis',
    '- we work closely with you to refine your concept, assess feasibility, and design a prototype that meets your functional requirements and objectives.',
  ],
  [
    '3D CAD Modeling and Design',
    '- using advanced CAD tools, we create precise, detailed 3D models that allow for early design evaluations and modifications, helping to identify potential improvements upfront.',
  ],
  [
    'PCB Design and Prototyping',
    '- PCB prototyping services ensure accurate, high-performance boards for complex hardware, allowing for quick testing and iterative improvements.',
  ],
  [
    'Mechanical Prototyping and Enclosure Design',
    '- design and fabricate enclosures and mechanical components, combining function with aesthetics to provide a complete, user-friendly prototype.',
  ],
  [
    'Additive Manufacturing and 3D Printing',
    '- through rapid prototyping methods like 3D printing, we produce parts quickly, enabling you to test and iterate on designs with minimal lead time.',
  ],
  [
    'Prototype Testing and Validation',
    '- we conduct rigorous testing to ensure that prototypes meet your technical and performance requirements, refining them as needed before moving toward final production.',
  ],
];
