export const mobileAppsData = [
  [
    'Custom Mobile App Development',
    '- build tailor-made applications designed to meet your unique business goals and user demands.',
  ],
  [
    'iOS App Development',
    '- deliver seamless, high-performance apps for Apple devices, leveraging Swift, Objective-C, and the latest iOS frameworks.',
  ],
  [
    'Android App Development',
    '- create versatile and feature-rich apps for the world’s most popular mobile platform using Kotlin and Java.',
  ],
  [
    'Cross-Platform Development',
    '- maximize reach and efficiency with apps that work flawlessly on both iOS and Android using Flutter and React Native.',
  ],
  [
    'UI/UX Design for Mobile',
    '- combine stunning design with exceptional usability to ensure an outstanding user experience.',
  ],
  [
    'App Maintenance and Updates',
    '- keep your app secure, up-to-date, and performing optimally with our ongoing support services.',
  ],
  [
    'MVP Development for Startups',
    '- launch your idea quickly with a Minimum Viable Product (MVP) that tests your concept and attracts early users.',
  ],
];
