export const machineLearningData = [
  [
    'Custom Machine Learning Models',
    '- build tailored ML models designed to solve your specific challenges, from anomaly detection to personalization engines.',
  ],
  [
    'Predictive Analytics',
    '- leverage advanced algorithms to forecast trends, optimize operations, and make smarter business decisions.',
  ],
  [
    'Natural Language Processing (NLP)',
    '- unlock the potential of text and voice data with NLP solutions, including chatbots, sentiment analysis, and language translation.',
  ],
  [
    'Computer Vision Solutions',
    '- analyze and interpret visual data with object recognition, facial detection, and image classification tailored to your applications.',
  ],
  [
    'AI-Powered Automation',
    '- automate repetitive processes, reduce operational costs, and enhance efficiency with intelligent algorithms.',
  ],
  [
    'Model Optimization and Maintenance',
    '- ensure your machine learning models stay accurate and up-to-date with continuous optimization and monitoring.',
  ],
];
