import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  const [first, second] = data;
  const [firstTitle, firstContent] = first;
  const [secondTitle, secondContent] = second;

  return (
    <Wrapper>
      <HeaderText>How We’re Different – Codahead’s Approach to Prototyping vs. Traditional Methods</HeaderText>
      <Text>{firstTitle}</Text>
      <Content>
        {firstContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>

      <Text>{secondTitle}</Text>
      <Content>
        {secondContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  [
    'Traditional Prototyping:',
    [
      {
        title: 'Slower Development Cycles',
        sub: 'Traditional methods often involve lengthy development timelines and limited opportunities for iteration.',
      },
      {
        title: 'Higher Costs for Modifications',
        sub: 'Traditional approaches may require costly reworks for any design changes, leading to longer lead times and additional costs.',
      },
      {
        title: 'Limited Collaboration',
        sub: 'Often lacking in frequent client feedback, traditional approaches may miss the mark on specific functional or aesthetic goals.',
      },
    ],
  ],
  [
    'Our Approach:',
    [
      {
        title: 'Streamlined Process and Fast Turnaround',
        sub: 'With a focus on speed and precision, we deliver prototypes faster than traditional methods, helping you accelerate your go-to-market timeline.',
      },
      {
        title: 'Collaborative, Client-Centric Model',
        sub: 'Our engineers work closely with you at every step, ensuring the prototype aligns with your goals, aesthetics, and functional requirements.',
      },
      {
        title: 'Iterative Testing and Immediate Feedback',
        sub: 'We embed testing and validation within our process, allowing us to refine prototypes in real-time based on performance feedback and functionality checks.',
      },
    ],
  ],
] as const;

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin: 58px auto 23px auto;
  width: 100%;
  justify-items: center;
  max-width: 1000px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
