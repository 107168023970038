export const wirelessConnectivityData = [
  [
    'IoT connectivity and integration',
    '- enable IoT devices to communicate seamlessly with the cloud and each other, creating smart, efficient ecosystems.',
  ],
  [
    'Custom Wireless Protocol Development',
    '- develop and implement wireless protocols like BLE, Zigbee, LoRa, or proprietary protocols tailored to your application’s unique requirements.',
  ],
  [
    'Wi-Fi and Cellular Integration',
    '- incorporate advanced Wi-Fi or LTE/5G technologies into your devices, ensuring reliable, high-speed data transfer for critical applications.',
  ],
  [
    'Short-Range Wireless Communication',
    '- build robust short-range systems using Bluetooth, NFC, or Ultra-Wideband (UWB) technologies for secure, low-power communication.',
  ],
  [
    'Low-Power Connectivity Solutions',
    '- optimize devices for low-energy protocols like BLE or LPWAN, maximizing battery life while maintaining strong connectivity.',
  ],
  [
    'Testing, Validation, and Optimization',
    '- ensure optimal performance, security, and compliance with comprehensive testing and validation of wireless systems.',
  ],
];
