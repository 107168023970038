export const usOffshoreSoftwareDevelopmentData = [
  [
    'Cost Savings without Compromising Quality',
    '- top engineering talent at a fraction of the cost, ensuring high-quality output while significantly reducing development expenses.',
  ],
  [
    'Accelerated Development Cycles',
    '- flexible, global team, we work across time zones to keep your projects moving around the clock, bringing products to market faster.',
  ],
  [
    'Specialized Talent',
    '- specialists in software development, cloud services, AI, and emerging technologies, providing the expertise needed for complex projects.',
  ],
  [
    'Reduced Operational Load',
    '- leave recruitment, training, and administrative tasks to us, freeing up your resources to focus on core business activities and strategic goals.',
  ],
  [
    'Full Transparency and Control',
    '- real-time communication, progress tracking, and reporting, we provide complete visibility into each stage of development, ensuring you’re always informed and in control.',
  ],
  [
    'Long-Term Scalability',
    '- our offshore model scales with you, allowing for smooth expansion or reduction of resources as required.',
  ],
];
