import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  const [first, second] = data;
  const [firstTitle, firstContent] = first;
  const [secondTitle, secondContent] = second;

  return (
    <Wrapper>
      <HeaderText>How We Perform – Codahead’s Approach vs. Traditional Offshore Development</HeaderText>
      <Text>{firstTitle}</Text>
      <Content>
        {firstContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>

      <Text>{secondTitle}</Text>
      <Content>
        {secondContent.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  [
    'Our Approach:',
    [
      {
        title: 'Client-Centric Partnership',
        sub: 'We prioritize clear communication, aligning our goals with yours to ensure your vision is realized.',
      },
      {
        title: 'Dedicated Project Managers',
        sub: 'Our managers provide streamlined coordination and oversee every phase, eliminating the common miscommunications in traditional offshore models.',
      },
      {
        title: 'Seamless Team Integration',
        sub: 'By using familiar project management tools and agile workflows, our team integrates smoothly with yours, making collaboration natural and effective.',
      },
      {
        title: 'Quality Assurance Built-In',
        sub: 'We embed QA and testing throughout development, ensuring a robust final product without costly rework.',
      },
    ],
  ],
  [
    'Traditional Offshore Development:',
    [
      {
        title: 'Limited Communication',
        sub: 'Many traditional models struggle with time zone delays and communication barriers, leading to misaligned expectations.',
      },
      {
        title: 'Higher Operational Overheads',
        sub: 'Traditional models often require substantial client oversight, adding to project timelines and resource use.',
      },
      {
        title: 'Variable Quality Standards',
        sub: 'Without integrated QA, many projects encounter errors or gaps that require additional fixes, impacting time-to-market and budget.',
      },
    ],
  ],
] as const;

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Text = styled.div`
  margin: 58px auto 23px auto;
  width: 100%;
  justify-items: center;
  max-width: 1000px;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
