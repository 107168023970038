export const qualityAssuranceData = [
  ['Manual Testing', '- ensure every aspect of your application works perfectly with detailed, hands-on testing.'],
  ['Automated Testing', '- accelerate testing cycles and increase efficiency with cutting-edge automation tools.'],
  [
    'Performance Testing',
    '- validate your software’s speed, scalability, and reliability under real-world conditions.',
  ],
  ['Security Testing', '- safeguard your application from vulnerabilities with comprehensive security assessments.'],
  [
    'Mobile and Cross-Platform Testing',
    '- guarantee seamless functionality across devices, operating systems, and screen sizes.',
  ],
  ['Regression Testing', '- detect bugs in updated code to maintain high-quality releases after every change.'],
  ['API Testing', '- ensure data integrity and seamless communication between different software components.'],
];
