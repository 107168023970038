import ArrowSvg from 'assets/images/_infoGraphics/arrow.svg';
import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import { navigate } from 'gatsby';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import { TAKE_THIS_STEP_ID } from 'shared/links';
import { ROUTE_CONTACT, ROUTE_JOIN_US, ROUTE_OTHER_INQUIRIES } from 'shared/paths';
import styled, { css } from 'styled-components';
import { B2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { drawTextWithLineBreaks } from 'utils/strings';

export const StepButton = styled(Common.Div)(
  ({ theme: { colors, spacing } }) =>
    css`
      background: ${colors.buttons};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${spacing(1)} ${spacing(3)};
      margin-bottom: ${spacing(4)};
      width: 255px;
      height: 41px;

      p {
        font-size: 14px;
      }

      ${mediaQueries.xs} {
        width: 100%;
      }
    `
);

const TEXT_OUR_SERVICES = `Our services inquiry, pricing,\n partnerships.`;
const TEXT_QUESTIONS = `For all other questions,\n please contact us here.`;
const TEXT_RECRUITMENT = `Take part in our recruitment\n process and join our team.`;

export const TakeStepText = styled(Common.Div)(
  ({ theme: { fontSizes } }) =>
    css`
      font-size: ${fontSizes.b3};
      line-height: 25px;
    `
);

const StyledSection = styled(Section)`
  background: #303030;
`;

export const TakeThisStepSection = () => {
  const isPadV = useBreakpoint('ipadV');
  const isMd = useBreakpoint('md');

  return (
    <StyledSection title="take this step" variant="secondary" id={TAKE_THIS_STEP_ID}>
      <Common.Div
        flex={isPadV ? 'column' : 'row'}
        mt={isMd ? 4 : 8}
        mb={isMd ? 0 : 2}
        ml={isMd ? 0 : 8}
        justifyContent="left"
      >
        <Common.Div flex="column" mb={isPadV ? 4 : 0} mr={isPadV ? 0 : 2}>
          <StepButton onClick={() => navigate(ROUTE_CONTACT)}>
            <B2>contact sales</B2>
            <Common.Svg src={ArrowSvg} />
          </StepButton>
          <Common.Div w={isMd ? '100%' : '240px'}>
            <TakeStepText>{drawTextWithLineBreaks(TEXT_OUR_SERVICES)}</TakeStepText>
          </Common.Div>
        </Common.Div>
        <Common.Div flex="column" mb={isPadV ? 4 : 0} mr={isPadV ? 0 : 2}>
          <StepButton onClick={() => navigate(ROUTE_OTHER_INQUIRIES)}>
            <B2>other inquiries</B2>
            <Common.Svg src={ArrowSvg} />
          </StepButton>
          <Common.Div w={isMd ? '100%' : '240px'}>
            <TakeStepText>{drawTextWithLineBreaks(TEXT_QUESTIONS)}</TakeStepText>
          </Common.Div>
        </Common.Div>
        <Common.Div flex="column" mb={isPadV ? 4 : 0}>
          <StepButton onClick={() => navigate(ROUTE_JOIN_US)}>
            <B2>career</B2>
            <Common.Svg src={ArrowSvg} />
          </StepButton>
          <Common.Div w={isMd ? '100%' : '240px'}>
            <TakeStepText>{drawTextWithLineBreaks(TEXT_RECRUITMENT)}</TakeStepText>
          </Common.Div>
        </Common.Div>
      </Common.Div>
    </StyledSection>
  );
};
