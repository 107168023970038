import React from 'react';
import styled from 'styled-components';
import iconBluetooth from 'assets/images/_icons/iconBluetooth.svg';
import iconBranch from 'assets/images/_icons/iconBranch.svg';
import iconFingerprint from 'assets/images/_icons/iconFingerprint.svg';
import iconTerminal from 'assets/images/_icons/iconTerminal.svg';
import iconWatch from 'assets/images/_icons/iconWatch.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Wireless Connectivity Solutions?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconBluetooth,
    title: 'Deep Expertise Across Protocols',
    sub: 'From BLE and Wi-Fi to LoRa and cellular technologies, we have in-depth knowledge of the most widely used wireless standards.',
  },
  {
    icon: iconTerminal,
    title: 'End-to-End Development Support',
    sub: 'From designing protocols to integrating hardware and validating performance, we provide a complete suite of services.',
  },
  {
    icon: iconBranch,
    title: 'Custom-Tailored Solutions',
    sub: 'Whether you need short-range, long-range, or low-power connectivity, we design solutions tailored to your specific application and goals.',
  },
  {
    icon: iconFingerprint,
    title: 'Focus on Security and Compliance',
    sub: 'We prioritize secure communication and compliance with global standards to protect your data and ensure smooth deployment.',
  },
  {
    icon: iconWatch,
    title: 'Accelerated Time-to-Market',
    sub: 'With an agile, collaborative approach, we reduce development cycles,  allowing you to launch innovative products faster.',
  },
];
