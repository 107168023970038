import React from 'react';
import styled from 'styled-components';
import iconAvatar from 'assets/images/_icons/iconAvatar.svg';
import iconCodeBrowser from 'assets/images/_icons/iconCodeBrowser.svg';
import iconDiamond from 'assets/images/_icons/iconDiamond.svg';
import iconPresentation from 'assets/images/_icons/iconPresentation.svg';
import iconStar from 'assets/images/_icons/iconStar.svg';
import iconUsers from 'assets/images/_icons/iconUserImageCheck.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Codahead for UI/UX Design?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconAvatar,
    title: 'User-Centered Philosophy',
    sub: 'We design for people first, ensuring that usability, accessibility and engagement remain at the core of every project.',
  },
  {
    icon: iconUsers,
    title: 'Experienced Design Team',
    sub: 'Leverage the expertise of designers who stay ahead of trends and consistently deliver award-winning results.',
  },
  {
    icon: iconStar,
    title: 'Collaborative Process',
    sub: 'Your vision drives our work. We collaborate closely with you to translate your ideas into stunning digital realities.',
  },
  {
    icon: iconPresentation,
    title: 'Data-Driven Decisions',
    sub: 'Our designs are informed by analytics, A/B testing, and user feedback to ensure optimal performance.',
  },
  {
    icon: iconCodeBrowser,
    title: 'Seamless Development Integration',
    sub: 'From concept to launch, our designs are optimized for smooth integration into development pipelines.',
  },
  {
    icon: iconDiamond,
    title: 'Brand Consistency',
    sub: 'We ensure that your designs reflect your unique brand identity while enhancing user experiences.',
  },
];
