import React from 'react';
import styled from 'styled-components';
import iconArrowsTriangle from 'assets/images/_icons/iconArrowsTriangle.svg';
import iconClock from 'assets/images/_icons/iconClock2.svg';
import iconGraduation from 'assets/images/_icons/iconGraduation2.svg';
import iconPasscode from 'assets/images/_icons/iconPasscode.svg';
import iconPiggyBank from 'assets/images/_icons/iconPiggyBank2.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Us for Firmware and Embedded Software Development?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconGraduation,
    title: 'Expertise across industries',
    sub: 'With experience in automotive, healthcare, IoT, and industrial automation, we tailor our solutions to fit the unique demands of your sector.',
  },
  {
    icon: iconArrowsTriangle,
    title: 'Seamless Hardware Integration',
    sub: 'Our firmware works harmoniously with your hardware, delivering smooth operation and superior performance from day one.',
  },
  {
    icon: iconPasscode,
    title: 'Focus on Security and Reliability',
    sub: 'We prioritize security and stability, using robust encryption protocols and quality assurance measures to safeguard your devices.',
  },
  {
    icon: iconPiggyBank,
    title: 'Cost-Effective, Scalable Solutions',
    sub: 'We design embedded systems that grow with your needs, offering flexibility and long-term value.',
  },
  {
    icon: iconClock,
    title: 'Accelerated Time-to-Market',
    sub: 'With an agile, collaborative approach, we reduce development cycles and help you launch products faster while maintaining high quality.',
  },
];
