export const paymentIntegrationData = [
  [
    'eCommerce Payment Systems',
    '- amplify online shopping experiences with integrations for platforms like Stripe, PayPal, and Adyen.',
  ],
  ['Mobile Payment Integration', '- enable seamless in-app transactions through Apple Pay, Google Pay, and more.'],
  [
    'Subscription and Recurring Billing',
    '- automate subscription management and recurring payments for SaaS, streaming and other subscription models.',
  ],
  [
    'Custom Gateway Development',
    "- tailored payment solutions to meet your business's unique needs, ensuring scalability and flexibility.",
  ],
  [
    'Multi-Currency Support',
    '- expand globally with systems that process transactions in multiple currencies effortlessly.',
  ],
  [
    'Fraud Detection and Security',
    '- implement advanced encryption, tokenization, and fraud detection to safeguard sensitive payment data.',
  ],
];
