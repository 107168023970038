import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';

export const BenefitsSection = () => {
  return (
    <Wrapper>
      <HeaderText>How We Outperform Traditional Electronics</HeaderText>
      <Content>
        {data.map(({ title, sub }, idx) => (
          <Item key={idx}>
            {title}
            <span>{sub}</span>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    title: 'Our Agile and Transparent Process',
    sub: 'Unlike traditional design services that may rely on inflexible processes, we use an agile approach, giving you full visibility and control at every phase.',
  },
  {
    title: 'Streamlined Team Integration',
    sub: 'We integrate seamlessly with your internal teams, so there’s no disconnect, no delays, and no missed details.',
  },
  {
    title: 'Enhanced Collaboration and Support',
    sub: 'We provide dedicated support and constant communication to ensure the final product aligns with your vision.',
  },
];

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.background};
  display: flex;
  flex-direction: column;
  padding: 118px 156px 92px 156px;
  width: 100%;

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Content = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  max-width: 1000px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    max-width: unset;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: start;
    max-width: unset;
  }
`;

const HeaderText = styled.div`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.heading};
  font-size: 36px;
  font-weight: 300;
  line-height: 1.53;
`;

const Item = styled.div`
  padding: 50px 32px;
  background-color: ${({ theme: { colors } }) => colors.section};
  font-size: 20px;
  font-weight: 500;

  span {
    font-size: 14px;
    font-weight: 300;
    display: block;
    line-height: 1.79;
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
