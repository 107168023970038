import React from 'react';
import styled from 'styled-components';
import iconCalendar from 'assets/images/_icons/iconCalendar3.svg';
import iconCertificate from 'assets/images/_icons/iconCertificate.svg';
import iconCurve from 'assets/images/_icons/iconCurve.svg';
import iconPenPlus from 'assets/images/_icons/iconPenPlus.svg';
import iconTool from 'assets/images/_icons/iconTool.svg';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';

const Wrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.section};
  display: flex;
  flex-direction: column;
  padding: 156px;
  width: 100%;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};

  ${mediaQueries.md} {
    padding: 44px 32px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 64px;
  background: ${({ theme: { colors } }) => colors.background};

  ${mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 36px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 300;
  padding: 40px 0;

  ${mediaQueries.sm} {
    padding: 0 0 40px 0;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    color: #f6f9ff;
    margin-top: 12px;
    display: block;
    max-width: 720px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 50px;
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 64px;
  justify-content: center;
  align-items: center;

  ${mediaQueries.sm} {
    padding: 48px 0;
    width: 100%;
  }
`;

export const ChooseCodaheadSection = () => {
  return (
    <Wrapper>
      <Title>Why Choose Our Electronics Design Services?</Title>
      <Content>
        {data.map(({ icon, title, sub }, idx) => (
          <Item key={idx}>
            <IconWrapper>
              <Common.Svg h="48px" w="48px" src={icon} />
            </IconWrapper>
            <Text>
              {title}
              <span>{sub}</span>
            </Text>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

const data = [
  {
    icon: iconCertificate,
    title: 'Proven Expertise and Industry Knowledge',
    sub: 'With extensive experience in diverse industries, we bring a depth of knowledge to each project, ensuring reliable, efficient, and innovative solutions.',
  },
  {
    icon: iconPenPlus,
    title: 'Full-Service Design from Concept to Production',
    sub: 'Our approach covers the entire product lifecycle, from initial concept to prototyping, validation, and beyond, ensuring quality at every stage.',
  },
  {
    icon: iconCurve,
    title: 'Customized to Fit Your Vision and Budget',
    sub: 'We align our process with your specific needs, adapting to your vision, timeline, and budget constraints to deliver results that exceed expectations.',
  },
  {
    icon: iconTool,
    title: 'State-of-the-Art Tools and Processes',
    sub: 'Using the latest tools in electronics design and CAD modeling, our team ensures high precision and scalability for even the most complex designs.',
  },
  {
    icon: iconCalendar,
    title: 'Proven Track Record in Timely Delivery',
    sub: 'With a focus on clear communication and efficient workflows, we consistently meet deadlines, helping you move quickly from concept to market.',
  },
];
