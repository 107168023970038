import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import styled from 'styled-components';
import { BenefitsSection } from 'sections/StaffAugmentation/UsOffshoreSoftwareDevelopment/components/BenefitsSection';
import { ImageWithTextSection } from 'sections/StaffAugmentation/UsOffshoreSoftwareDevelopment/components/ImageWithTextSection';
import { ChooseCodaheadSection } from 'sections/StaffAugmentation/UsOffshoreSoftwareDevelopment/components/ChooseCodaheadSection';
import { DistinctSection } from 'sections/StaffAugmentation/UsOffshoreSoftwareDevelopment/components/DistinctSection';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Staff augmentation"
        meta={[
          {
            name: 'keywords',
            content: 'managed teams, outsourcing, US offshore software development, development for startups',
          },
        ]}
        description={
          'Need extra hands for a project or want to expand your capabilities quickly? Our staff augmentation services give you access to top talent exactly when you need it. Whether it’s for short-term projects or long-term growth, we provide skilled professionals to seamlessly integrate with your team, helping you achieve your goals faster and more efficiently. Looking for the right fit? Look no further!'
        }
      />

      <CommonMargin>
        <StyledSection
          title="US offshore software development"
          variant="secondary"
          id="US offshore software development"
          isMobile={isMd}
          withBreadCrumbs
        >
          <HeaderSection>
            Our US offshore software development services bring you top talent, reduced costs, and faster delivery—all
            with a personal touch. With over 16 years in offshore development, we’re like an extension of your team,
            handling the details so you can focus on the big picture. From idea to product, we’re here to transform your
            vision into a powerful solution while saving you time, effort, and budget.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <ImageWithTextSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
