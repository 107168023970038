export const outsourcingData = [
  [
    'Global Talent and Specialized Skills',
    '- experts across software development, IT support, and digital transformation, bringing advanced capabilities and fresh perspectives to your projects.',
  ],
  [
    'Cost-Effective and Scalable Solutions',
    '- optimize your budget with flexible outsourcing models that allow you to scale resources based on project requirements, without the long-term commitment of in-house hiring.',
  ],
  [
    'Faster Project Turnaround',
    '- dedicated outsourced teams, projects progress smoothly from start to finish, reducing time-to-market and helping you seize new opportunities faster.',
  ],
  [
    'Enhanced Focus on Core Business Functions',
    '- free up internal teams to focus on strategic initiatives, driving innovation and growth in your core business.',
  ],
  [
    'Risk mitigation and QA',
    '- prioritize compliance, quality control, and risk management to ensure a reliable, secure, and high-quality output that meets your standards.',
  ],
  [
    '24/7 Operational Flexibility',
    '- teams working across different time zones, we provide continuous progress on your projects, delivering faster outcomes and maximizing productivity.',
  ],
];
